/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled from 'styled-components'

interface UspBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledUspBlocks = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;

  @media (min-width: 992px) {
    & h2 {
      font-size: 75px !important;
    }
  }
`

const BlockContent = styled.div``

const Block = styled.div`
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 40px;
  background-color: ${({ theme }) => theme.color.light};
  width: 340px;
  height: 460px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 14px;
    line-height: 25px;
  }

  @media screen and (max-width: 1024px) {
    padding: 15px 20px;
    width: 270px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 20px 50px;
    margin-bottom: 20px;
    height: fit-content;
    flex-direction: row;

    ${BlockContent} {
      padding-left: 50px;

      & p {
        text-align: left !important;
      }
    }
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
    padding: 20px 20px;

    ${BlockContent} {
      padding-left: 0;

      & p {
        text-align: center !important;
      }
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  width: 160px;
  height: 160px;
  margin-top: 20px;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 1024px) {
    height: 120px;
    width: 120px;
  }

  @media screen and (max-width: 575px) {
    height: 100px;
    width: 100px;
    margin-bottom: 20px;
  }
`

const BackgroundBar = styled.div`
  position: absolute;
  z-index: 1;
  height: 200px;
  width: 100%;
  top: 100px;
  left: 0;
  background: linear-gradient(90deg, #09154f 7.53%, #1402ac 117.72%);

  @media screen and (max-width: 991px) {
    height: 70%;
  }

  @media screen and (max-width: 575px) {
    height: 79%;
  }
`

const BlockContainer = styled.div`
  position: relative;
  z-index: 2;
`

const AltContent = styled(ParseContent)`
  & p {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media screen and (max-width: 1024px) {
    padding-left: 50px;
  }

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
`

const UspBlocks: React.FC<UspBlocksProps> = ({ fields }) => (
  <StyledUspBlocks>
    <div className="container pt-lg-5 pt-3">
      <div className="row">
        <div className="col-lg-4">
          <ParseContent content={fields.title || ''} />
        </div>
        <div className="col-lg-8">
          <AltContent content={fields.description || ''} />
        </div>
      </div>
    </div>
    <div className="position-relative py-lg-5 pt-5 pb-3">
      <BackgroundBar />
      <BlockContainer className="container">
        <div className="d-flex flex-wrap justify-content-around">
          {fields.blocks?.map((b, index) => (
            <Block key={index}>
              <div>
                <ParseContent content={b?.title || ''} />
                <div className="d-flex justify-content-center">
                  <StyledPlaatjie image={b?.icon} alt="" />
                </div>
              </div>
              <BlockContent>
                <ParseContent content={b?.description || ''} />
              </BlockContent>
            </Block>
          ))}
        </div>
        <div className="d-flex justify-content-center pt-lg-5 pt-3">
          <Button
            className="filled secondary hover-secondary"
            arrow
            to={fields.link?.url || '/'}
          >
            {fields.link?.title}
          </Button>
        </div>
      </BlockContainer>
    </div>
  </StyledUspBlocks>
)

export default UspBlocks
