import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Hooks
import useLayout from 'hooks/useLayout'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import FormNextStep from '../Form/FormNextStep'
import Button from 'components/elements/Buttons/Button'

interface CTAProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledCTA = styled.section`
  background-color: ${({ theme }) => theme.color.light};
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 250px;
  width: 250px;

  @media screen and (max-width: 1024px) {
    height: 220px;
    width: 220px;
  }
`

const Content = styled(ParseContent)`
  & h3 {
    padding-bottom: 20px;
  }

  & p {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 1024px) {
    & p {
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: 575px) {
    & h3 {
      padding-bottom: 10px;
    }
  }
`

const CTA: React.FC<CTAProps> = ({ fields }) => {
  const layout = useLayout()
  const [popup, setPopup] = React.useState(false)

  return (
    <StyledCTA>
      <div className="container py-lg-5">
        <div className="row py-lg-5 py-3 justify-content-center">
          <div className="col-lg-3 col-md-4 d-md-block d-flex justify-content-center pb-md-0 pb-3">
            <StyledPlaatjie
              image={layout.darkMode ? fields.altimage : fields.image}
              alt=""
            />
          </div>
          <div className="col-xl-5 col-lg-7 col-md-8 d-flex align-items-center">
            <div>
              <Content content={fields.description || ''} />

              <Button
                className="button filled secondary hover-secondary mt-3"
                arrow
                isCustom
                onClick={() => setPopup(true)}
              >
                <button type="button" className="font-weight-bold">
                  {layout.locale === 'nl_NL'
                    ? 'Vraag hier jouw marktwaarde aan'
                    : 'Request your marketvalue here'}
                </button>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <FormNextStep
          step={2}
          isOpen={popup}
          requestClose={() => setPopup(false)}
        />
      )}
    </StyledCTA>
  )
}

export default CTA
