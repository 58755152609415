/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Third Party
import styled from 'styled-components'

// Hooks
import useLayout from 'hooks/useLayout'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import FormNextStep from 'components/flex/Form/FormNextStep'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import Button from 'components/elements/Buttons/Button'

interface StepsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledSteps = styled.section`
  background-color: ${({ theme }) => theme.color.light};
`

const ImageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 1140px;
  background-color: ${({ theme }) => theme.color.primary};
  left: 400px;
  z-index: 1;
  top: 0;

  @media screen and (max-width: 1024px) {
    left: 200px;
  }

  @media screen and (max-width: 991px) {
    left: 150px;
  }

  @media screen and (max-width: 575px) {
    left: 0;
    /* width: 100%; */
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  mix-blend-mode: overlay;
`

const StepsContainer = styled.div`
  position: relative;
  z-index: 2;

  @media screen and (max-width: 991px) {
    & p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media screen and (max-width: 575px) {
    & * {
      text-align: center !important;
    }
  }
`

const IconWrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.color.border.primaryLight};
  border-radius: 26px;
  padding-top: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.color.light};

  @media screen and (max-width: 575px) {
    width: 140px;
    padding-top: 140px;
  }
`

const Icon = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 120px;
  height: 120px;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 575px) {
    width: 80px;
    height: 80px;
  }
`

const StepsImage = styled.div`
  @media screen and (max-width: 350px) {
    width: 100% !important;
    margin: 20px 0 10px 0;
  }
`

const StepsContent = styled.div`
  @media screen and (max-width: 350px) {
    width: 100% !important;
  }
`

const Steps: React.FC<StepsProps> = ({ fields }) => {
  const layout = useLayout()

  const [popup, setPopup] = useState(false)
  const [clickedStep, setClickedStep] = useState(0)

  return (
    <StyledSteps className="pb-lg-5 pb-3 pt-lg-0 pt-4">
      <div className="container py-lg-5 py-3">
        <div className="pb-lg-5 pb-3">
          <ParseContent content={fields.title || ''} />
        </div>
        <div className="row py-lg-5 py-3 position-relative justify-content-center">
          <ImageContainer>
            <StyledPlaatjie image={fields.image} alt="" />
          </ImageContainer>
          <StepsContainer className="col-xl-8">
            {fields.steps?.map((s, index) => {
              const handleForm = () => {
                setPopup(true)
                setClickedStep(index)
              }

              return (
                <div
                  key={index}
                  className="row justify-content-center py-lg-3 py-2"
                >
                  <StepsImage className="col-sm-3 col-md-4 col-xl-5">
                    <div className="d-sm-block d-flex justify-content-center align-items-center h-100">
                      <IconWrapper>
                        <Icon
                          image={layout.darkMode ? s?.darkicon : s?.icon}
                          alt=""
                        />
                      </IconWrapper>
                    </div>
                  </StepsImage>
                  <StepsContent className="col-sm-7 d-flex align-items-center">
                    <div className="ps-lg-5 ps-0">
                      <ParseContent content={s?.description || ''} />
                      <Button
                        className="button filled secondary hover-secondary mt-3"
                        arrow
                        isCustom
                        onClick={handleForm}
                      >
                        <button type="button" className="font-weight-bold">
                          {s?.link?.title}
                        </button>
                      </Button>
                    </div>
                  </StepsContent>
                </div>
              )
            })}
          </StepsContainer>
          {popup && (
            <FormNextStep
              step={clickedStep}
              isOpen={popup}
              requestClose={() => setPopup(false)}
            />
          )}
        </div>
      </div>
    </StyledSteps>
  )
}

export default Steps
