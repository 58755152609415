import React from 'react'
import styled from 'styled-components'

// Third party
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import Modal from 'react-modal'

// Components
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'

// Images
import Cancel from 'img/cancel.inline.svg'
import useLayout from 'hooks/useLayout'
import { useMedia } from 'react-use'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  color: ${({ theme }) => theme.color.dark};
  overflow-x: hidden;

  & .form-duuf-group {
    width: 100%;
    margin-bottom: 2rem;
    position: relative;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    border-radius: unset;
    padding: 0.8rem 1rem;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-group-consent {
    & a {
      color: ${({ theme }) => theme.color.secondary} !important;
      text-decoration: underline;
    }

    & input {
      margin-right: 10px;
    }
  }

  & .form-duuf-file-button {
    ${ButtonPrimaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    top: 0;
    right: 10px;
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    width: 156px;
    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    margin: 0 auto;
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.huge};
    margin-bottom: 3rem;
    @media (min-width: 576px) {
      width: 75%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 575px) {
    overflow-y: scroll !important;
  }
`

const SvgWrapper = styled.div`
  right: 25px;

  & svg {
    width: 25px;
    height: 25px;

    & path {
      fill: ${({ theme }) => theme.color.dark};
    }
  }
`

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.color.light};
`

interface FormNextStepProps {
  isOpen: boolean
  requestClose: any
  step: number
}

const FormNextStep: React.FC<FormNextStepProps> = ({
  isOpen,
  requestClose,
  step,
}) => {
  const layout = useLayout()

  const mobile = useMedia('(max-width: 575px)')

  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      height: mobile ? '90%' : 'auto',
      width: mobile ? '100%' : '50%',
      overflowY: mobile ? 'scroll' : 'none',
      zIndex: 999,
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '24px',
      padding: '3rem',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      background: 'rgba(9, 21, 79, 0.25)',
    },
  }

  let clickedStep: string
  let clickedNLStep: string
  let clickedENStep: string
  let enTitle: string
  let nlTitle: string

  switch (step) {
    case 0:
      clickedNLStep = 'Oriënterend gesprek'
      clickedENStep = 'Exploratory meeting'
      enTitle = 'Schedule an exploratory meeting'
      nlTitle = 'Plan een oriënterend gesprek'
      break
    case 1:
      clickedNLStep = 'CV beoordelen'
      clickedENStep = 'CV assessed'
      enTitle = 'Have your CV assessed'
      nlTitle = 'Laat jouw CV beoordelen'
      break
    case 2:
      clickedNLStep = 'Marktwaarde checken'
      clickedENStep = 'Check marketvalue'
      enTitle = 'Check your marketvalue'
      nlTitle = 'Check jouw marktwaarde'
      break
    default:
      clickedNLStep = 'Alle stappen'
      clickedENStep = 'All steps'
      enTitle = 'Check everything'
      nlTitle = 'Alles checken'
  }

  if (layout.locale === 'nl_NL') {
    clickedStep = clickedNLStep
  } else {
    clickedStep = clickedENStep
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={requestClose}
      // @ts-ignore
      style={customStyles}
    >
      <StyledFormDuuf
        id={layout.locale === 'nl_NL' ? 9 : 10}
        generate={false}
        defaultFields={{
          input_9: clickedStep,
        }}
        privacyUrl={
          layout.locale === 'nl_NL' ? '/nl/privacyverklaring' : '/privacy'
        }
        privacyUrlPlacement={
          layout.locale === 'nl_NL' ? 'privacyvoorwaarden' : 'Privacy Policy'
        }
      >
        <SvgWrapper className="d-flex justify-content-end mb-4">
          <Cancel onClick={() => requestClose()} role="button" />
        </SvgWrapper>
        <div className="row">
          <h2 className="mb-4">
            {layout.locale === 'nl_NL' ? nlTitle : enTitle}
          </h2>
          <div className="col-md-6">
            <FormDuufGroup showIds={[1, 3, 4]} />
          </div>
          <div className="col-md-6">
            <FormDuufGroup showIds={[2, 7]} />
          </div>
          <div className="col-12">
            <FormDuufGroup showIds={[8]} />
          </div>
          <div className="col-12">
            <FormDuufGroup showIds={[10]} />
          </div>
          <div className="col-12">
            <FormDuufSubmit />
          </div>
        </div>
      </StyledFormDuuf>
    </StyledModal>
  )
}

export default FormNextStep
