/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import ParseContent from 'components/shared/ParseContent'
import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

import Down from 'img/down.inline.svg'
import { useMedia } from 'react-use'

interface ServicesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledServices = styled.div`
  background-color: ${({ theme }) => theme.color.light};
`

const Content = styled(ParseContent)`
  & h2 {
    padding-bottom: 15px;

    @media screen and (min-width: 991px) {
      font-size: 75px;
      line-height: 70px;
    }
  }
`

const Block = styled(motion.div)`
  position: relative;
  margin: 12px 0;

  @media screen and (min-width: 1440px) {
    padding-top: 85%;
  }

  @media screen and (max-width: 1439px) {
    padding-top: 100%;
  }

  @media screen and (max-width: 575px) {
    padding-top: unset;
    height: fit-content;
  }
`

const BlockContent = styled.div`
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 40px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.light};
  padding: 30px;

  & p {
    font-size: 14px;
    line-height: 25px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media screen and (max-width: 1024px) {
    padding: 10px 10px;

    & h3 {
      font-size: 20px;
      line-height: 24px;
    }

    & p {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 30px;

    & h3 {
      font-size: 24px;
      line-height: 24px;
    }

    & p {
      font-size: 14px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 575px) {
    position: relative;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 120px;
  width: 160px;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 1024px) {
    height: 80px;
    width: 120px;
  }

  @media screen and (max-width: 991px) {
    height: 120px;
    width: 160px;
  }
`

const BackDrop = styled.div`
  position: absolute;
  height: 84%;
  left: 8%;
  top: 8%;
  min-width: 1200px;
  border-radius: 45px;
  background: linear-gradient(90deg, #09154f 7.53%, #1402ac 117.72%);

  @media screen and (max-width: 575px) {
    left: 0;
    border-radius: 0;
  }
`

const SvgWrapper = styled(motion.div)`
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
  cursor: pointer;
  margin-bottom: -0.1rem;

  & svg {
    height: 40px;
    width: 40px;
  }
`

const Services: React.FC<ServicesProps> = ({ fields }) => {
  const mobile = useMedia('(max-width: 575px)')
  const [open, setOpen] = React.useState(-1)

  return (
    <StyledServices id={fields.sectionid} className="pt-5">
      <div className="container">
        <div className="row ">
          <div className="col-lg-5 pb-lg-0 pb-4 d-flex align-items-center">
            <div className="pe-lg-5">
              <Content
                content={fields.description || ''}
                className="clearfix"
              />
              {fields.link && (
                <div className="pt-lg-5 pt-3">
                  <Button
                    arrow
                    className="filled secondary hover-secondary"
                    to={fields.link?.url || '/'}
                  >
                    {fields.link?.title}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row justify-content-center position-relative">
              <BackDrop />
              {fields.services?.map((s, index) => (
                <div className="col-md-6 col-sm-10 mb-5" key={index}>
                  <Block
                    initial={
                      mobile ? { height: 450 } : { height: 'fit-content' }
                    }
                    animate={
                      open !== index
                        ? { height: 'fit-content' }
                        : mobile
                        ? { height: 'fit-content' }
                        : { height: 450 }
                    }
                    transition={{
                      type: 'spring',
                      damping: 20,
                    }}
                  >
                    <BlockContent className="d-flex flex-column justify-content-between">
                      <div>
                        <div className="d-flex justify-content-center py-xl-4 py-lg-2 py-3">
                          <StyledPlaatjie image={s?.icon} alt="" />
                        </div>
                        <ParseContent content={s?.title || ''} />
                      </div>

                      {open === index && (
                        <div className="pb-5">
                          <ParseContent
                            content={s?.description || ''}
                            className="mb-4"
                          />
                        </div>
                      )}
                    </BlockContent>

                    <SvgWrapper
                      onClick={() => setOpen(open === index ? -1 : index)}
                      animate={
                        open === index
                          ? { transform: 'rotate(180deg)' }
                          : { transform: 'rotate(0deg)' }
                      }
                    >
                      <Down />
                    </SvgWrapper>
                  </Block>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </StyledServices>
  )
}

export default Services
