/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import Zootjie from 'img/zootjie.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import useLayout from 'hooks/useLayout'

interface WorkmethodProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledWorkmethod = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  padding: 30px 0 450px 0;

  & h2 {
    @media screen and (min-width: 991px) {
      font-size: 75px;
      line-height: 70px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }
`

const Content = styled(ParseContent)`
  max-width: 220px;

  & p {
    font-size: 14px;
    line-height: 25px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const SvgWrapper = styled.div<{ darkmode: boolean }>`
  position: absolute;
  z-index: 3;
  width: 1952px;
  left: -1299px;
  top: -100px;

  & svg {
    width: 195%;
    fill: ${({ theme }) => theme.color.grey};
  }

  & path {
    ${({ darkmode }) =>
      darkmode &&
      css`
        fill: #fff;
      `}

    @media screen and (max-width: 1199px) {
      left: -1480px;
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }
`

const Block = styled.div<{ index: number }>`
  ${({ index }) =>
    index === 0 &&
    css`
      position: absolute;
      top: 147px;
      left: 145px;
      width: 123px;

      & .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 123px;
        height: 167px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 75px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        padding-top: 50px;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      @media screen and (max-width: 1199px) {
        top: 147px;
        left: -35px;
      }
    `}

  ${({ index }) =>
    index === 1 &&
    css`
      position: absolute;
      top: 147px;
      left: 347px;
      width: 122px;

      & .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 122px;
        height: 166px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 75px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        position: relative;
        padding-top: 50px;
        width: 130%;
        left: -15%;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      @media screen and (max-width: 1199px) {
        top: 147px;
        left: 166px;
      }
    `}

  ${({ index }) =>
    index === 2 &&
    css`
      position: absolute;
      top: 147px;
      left: 547px;
      width: 122px;

      & > .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 122px;
        height: 167px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 75px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        position: relative;
        padding-top: 50px;
        width: 130%;
        left: -15%;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      @media screen and (max-width: 1199px) {
        top: 147px;
        left: 366px;
      }
    `}

  ${({ index }) =>
    index === 3 &&
    css`
      position: absolute;
      top: 147px;
      left: 745px;
      width: 122px;

      & .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 122px;
        height: 167px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 75px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        position: relative;
        padding-top: 50px;
        width: 130%;
        left: -15%;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      @media screen and (max-width: 1199px) {
        top: 147px;
        left: 564px;
      }
    `}

  ${({ index }) =>
    index === 4 &&
    css`
      position: absolute;
      top: 100px;
      left: 948px;
      width: 208px;

      & > .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 208px;
        height: 208px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 75px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        padding-top: 56px;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      @media screen and (max-width: 1199px) {
        top: 100px;
        left: 767px;
      }
    `}

  @media screen and (max-width: 991px) {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: fit-content;
    margin: 20px 0 50px 0;
    display: flex;
    justify-content: center;

    &:last-of-type {
      margin: 20px 0;
    }

    & > .number {
      height: 140px;
      width: 140px;
      border-radius: 10px;
    }

    & .block-text {
      width: 40%;
      padding-top: 0px;
      left: 0;
      display: flex;
      align-items: center;

      & p {
        font-size: 16px;
        text-align: left !important;
      }
    }
  }

  @media screen and (max-width: 575px) {
    & > .number {
      height: 100px;
      width: 100px;
      border-radius: 10px;
    }

    & .block-text {
      width: 50%;
      padding-left: 15px;
    }
  }

  @media screen and (max-width: 350px) {
    & .block-text {
      width: 70%;
    }
  }
`

const Workmethod: React.FC<WorkmethodProps> = ({ fields }) => {
  const layout = useLayout()

  return (
    <StyledWorkmethod className="position-relative" id={fields.sectionid}>
      <div className="container py-lg-5">
        <ParseContent content={fields.title || ''} />
        <div className="w-100 position-relative py-lg-5 py-3">
          <SvgWrapper darkmode={layout.darkMode}>
            <Zootjie />
          </SvgWrapper>
          {fields.methods?.map((m, index) => (
            <Block key={index} index={index}>
              <div className="number">
                {`${index + 1}`}
                <b>.</b>
              </div>
              <div className="block-text d-flex justify-content-center">
                <Content content={m?.description || ''} />
              </div>
            </Block>
          ))}
        </div>
      </div>
    </StyledWorkmethod>
  )
}

export default Workmethod
