/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import ZootjieVerticaal from 'img/zootjieverticaal.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import useLayout from 'hooks/useLayout'

import Button from 'components/elements/Buttons/Button'

interface WorkmethodVerticalProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledWorkmethodVertical = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  padding: 30px 0 750px 0;
  overflow-x: hidden;

  & h2 {
    @media screen and (min-width: 991px) {
      font-size: 75px;
      line-height: 70px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }
`

const Content = styled(ParseContent)`
  max-width: 420px;
  font-family: ${({ theme }) => theme.font.family.secondary} !important;

  color: ${({ theme }) => theme.color.dark};
  & p {
    font-size: 14px;
    line-height: 25px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.dark};
  }
`

const SvgWrapper = styled.div<{ darkmode: boolean }>`
  position: absolute;
  z-index: 3;
  width: 1952px;
  left: -1662px;
  top: 100px;

  & svg {
    width: 195%;
    fill: ${({ theme }) => theme.color.grey};
  }

  & path {
    ${({ darkmode }) =>
      darkmode &&
      css`
        fill: #fff;
      `}

    @media screen and (max-width: 1199px) {
      /* left: -1772px; */
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }
`

const Block = styled.div<{ index: number }>`
  ${({ index }) =>
    index === 0 &&
    css`
      position: absolute;
      top: 114px;
      left: 377px;
      width: 85px;

      & .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 85px;
        height: 91px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 45px;
        line-height: 45px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        padding-top: 45px;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      /* @media screen and (max-width: 1199px) {
        top: 147px;
        left: -35px;
      } */
    `}

  ${({ index }) =>
    index === 1 &&
    css`
      position: absolute;
      top: 228px;
      left: 379px;
      width: 85px;

      & .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 85px;
        height: 91px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 45px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        position: relative;
        padding-top: 45px;
        width: 100%;
        left: -15%;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      /* @media screen and (max-width: 1199px) {
        top: 147px;
        left: 166px;
      } */
    `}

  ${({ index }) =>
    index === 2 &&
    css`
      position: absolute;
      top: 340px;
      left: 379px;
      width: 85px;

      & > .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 85px;
        height: 91px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 45px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        position: relative;
        padding-top: 50px;
        width: 100%;
        left: -15%;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      /* @media screen and (max-width: 1199px) {
        top: 147px;
        left: 366px;
      } */
    `}

  ${({ index }) =>
    index === 3 &&
    css`
      position: absolute;
      top: 454px;
      left: 379px;
      width: 85px;

      & .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 85px;
        height: 91px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 45px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        position: relative;
        padding-top: 50px;
        width: 100%;
        left: -15%;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      /* @media screen and (max-width: 1199px) {
        top: 147px;
        left: 564px;
      } */
    `}

  ${({ index }) =>
    index === 4 &&
    css`
      position: absolute;
      top: 594px;
      left: 337px;
      width: 208px;

      & > .number {
        background: linear-gradient(0deg, #09154f -1.33%, #1402ac 86.28%);
        width: 135px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.color.text.light};
        font-size: 50px;
        line-height: 70px;
        font-family: ${({ theme }) => theme.font.family.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};

        & b {
          color: ${({ theme }) => theme.color.text.secondary};
        }
      }

      & .block-text {
        padding-top: 56px;

        & p {
          font-weight: ${({ theme }) => theme.font.weight.light};
          font-size: 14px;
          line-height: 25px;
        }
      }

      /* @media screen and (max-width: 1199px) {
        top: 100px;
        left: 767px;
      } */
    `}

  @media screen and (max-width: 991px) {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: fit-content;
    margin: 20px 0 50px 0;
    display: flex;
    justify-content: center;

    &:last-of-type {
      margin: 20px 0;
    }

    & > .number {
      height: 140px;
      width: 140px;
      border-radius: 10px;
    }

    & .block-text {
      width: 40%;
      padding-top: 0px;
      left: 0;
      display: flex;
      align-items: center;

      & p {
        font-size: 16px;
        text-align: left !important;
      }
    }
  }

  @media screen and (max-width: 575px) {
    & > .number {
      height: 100px;
      width: 100px;
      border-radius: 10px;
    }

    & .block-text {
      width: 50%;
      padding-left: 15px;
    }
  }

  @media screen and (max-width: 350px) {
    & .block-text {
      width: 70%;
    }
  }
`

const Col = styled.div`
  background: ${({ theme }) => theme.color.grey};
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 2px solid #14e4b7;
  border-right: unset;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background: ${({ theme }) => theme.color.grey};
    border-top: 2px solid #14e4b7;
    border-bottom: 2px solid #14e4b7;
    @media (min-width: 992px) {
      margin-top: 6rem;
      margin-bottom: 3rem;
    }
    @media (max-width: 991px) {
      margin-bottom: 1rem;
      margin-top: 4rem;
    }
  }
`

const WorkmethodVertical: React.FC<WorkmethodVerticalProps> = ({ fields }) => {
  const layout = useLayout()

  return (
    <StyledWorkmethodVertical
      className="position-relative  pb-5"
      id={fields.sectionid}
    >
      <div className="container py-lg-5">
        <ParseContent content={fields.title || ''} />
        <div className="w-100 position-relative py-lg-5 py-3">
          <SvgWrapper darkmode={layout.darkMode}>
            <ZootjieVerticaal />
          </SvgWrapper>
          <div className="row justify-content-end gx-0">
            <Col className="col-lg-8 ms-5 ps-5 mt-5 pt-5">
              {fields.methodsvertical?.methodsz?.map((m, index) => (
                <>
                  <Block key={index} index={index}>
                    <div className="number">
                      {`${index + 1}`}
                      <b>_</b>
                    </div>
                  </Block>
                  <div className="block-text d-flex justify-content-start mb-4 pb-4 ms-md-5 ps-md-5">
                    <Content
                      content={m?.description || ''}
                      className="text-start ps-sm-5 ps-xl-0"
                    />
                  </div>
                </>
              ))}
              <div className="ps-5">
                <ParseContent
                  content={fields.methodsvertical?.infotext || ''}
                />
                <div style={{ marginTop: '2rem', marginBottom: '-1.5rem' }}>
                  <Button
                    to={fields.methodsvertical?.link?.url || '#'}
                    arrow
                    className="secondary filled hover-secondary"
                  >
                    {fields.methodsvertical?.link?.title}
                  </Button>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </StyledWorkmethodVertical>
  )
}

export default WorkmethodVertical
