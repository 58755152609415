/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled from 'styled-components'

interface SingleServiceProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledSingleService = styled.section`
  padding: 100px 0 100px 0;
  background-color: ${({ theme }) => theme.color.light};

  @media screen and (max-width: 991px) {
    padding: 0px 0;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    padding-bottom: 15px;

    @media screen and (min-width: 991px) {
      font-size: 75px;
      line-height: 70px;
    }
  }
`

const Block = styled.div`
  position: relative;
  margin: 12px 0;
  width: 400px;
  min-height: 400px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: fit-content;
  }

  @media screen and (max-width: 991px) {
    width: 340px;
  }

  @media screen and (max-width: 350px) {
    width: 300px;
  }
`

const BlockContent = styled.div`
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 40px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.light};
  padding: 30px;

  & p {
    font-size: 14px;
    line-height: 25px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media screen and (max-width: 1024px) {
    position: relative;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 120px;
  width: 120px;

  & img {
    object-fit: contain !important;
  }
`

const BackDrop = styled.div`
  position: absolute;
  height: 120%;
  right: 40%;
  top: -10%;
  min-width: 1200px;
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  background: linear-gradient(90deg, #09154f 7.53%, #1402ac 117.72%);

  @media screen and (max-width: 991px) {
    height: 73%;
    right: -40px;
    top: 10%;
  }
`

const SingleService: React.FC<SingleServiceProps> = ({ fields }) => (
  <StyledSingleService id={fields.sectionid}>
    <div className="container">
      <div className="row justify-content-between py-lg-5 py-3">
        <div className="col-lg-4">
          <div className="d-flex pb-lg-0 pb-3 justify-content-lg-end justify-content-center position-relative">
            <BackDrop />
            {fields.blocks?.map((s, index) => (
              <div key={index}>
                <Block>
                  <BlockContent className="d-flex flex-column justify-content-between">
                    <div>
                      <ParseContent content={s?.title || ''} />
                      <div className="d-flex justify-content-center py-4">
                        <StyledPlaatjie image={s?.icon} alt="" />
                      </div>
                    </div>

                    <ParseContent content={s?.description || ''} />
                  </BlockContent>
                </Block>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-8 d-flex align-items-center">
          <div className="px-xl-4">
            <Content content={fields.description || ''} />
            <div className="pt-xl-5 pt-lg-4 pt-3">
              <Button
                arrow
                className="filled secondary hover-secondary"
                to={fields.link?.url || '/'}
              >
                {fields.link?.title}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledSingleService>
)

export default SingleService
